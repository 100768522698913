<template lang="pug">
.wuwow-card.reg-notificationDetail
  .wuwow-card-head
    h4.h4-notificationDetail-title {{ notificationData.title }}
  .wuwow-card-body
    .are-notificationDetail-content(v-html='notificationData.content')
  .are-notificationDetail-attachment(v-if='notificationData.file && notificationData.file.length > 0')
    span.txt-attachment-title {{ $t('attackDownload') }}&#xFF1A;
    a.lnk-attachment(v-for='(item, index) in NotificationDetail.file' :key='index' :href='item.url' target='_blank') {{ item.name }}
  button.my-3.ml-3.btn.btn-outline-primary.pull-right(type='button' @click='back()')
    | {{ $t('router_back') }}
</template>

<script>
import LioshutanApi from '@/api';
import i18n from './lang';

export default {
  name: 'BulletinContent',

  i18n,

  data() {
    return {
      notificationData: {},
    };
  },

  computed: {},

  watch: {},

  created() {
    this.getNotificationSpecificContent();
  },

  methods: {
    async getNotificationSpecificContent(){
      const params = {
        type: this.$route.params.type,
        id: this.$route.params.id,
      };
      const res = await LioshutanApi.notification.getNotificationSpecificContent(params);
      this.notificationData = res.data.notification;
    },

    back() {
      this.$router.back(-1);
    },
  },
};
</script>
