export default {
  messages: {
    tw: {
      attackDownload: '附件下載',
    },

    en: {
      attackDownload: 'Download',
    },
  },
};
